@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;700;800;900&display=swap);
.Spin_spin-container__1K3sM{margin:20px 0;margin-bottom:20px;padding:30px 50px;text-align:center;border-radius:4px}

.Avatar_Container__HCx_Q{width:100%;max-width:200px}.Avatar_Container__HCx_Q .Avatar_item__2drqS{width:100%;height:36px;cursor:pointer;padding:12px 36px;display:flex;align-items:center;justify-content:center}.Avatar_Container__HCx_Q .Avatar_item__2drqS:hover{background-color:#F2F2F2}.Avatar_Container__HCx_Q .Avatar_item__2drqS span{font-weight:600;font-size:16px;line-height:20px}

.Menu_Menu__1fk5z{display:flex;width:50%}.Menu_Menu__1fk5z .Menu_item__22eVJ{max-width:25%;padding-right:49px;font-family:var(--font-family);font-weight:600;font-size:16px;line-height:20px;letter-spacing:0.2px;color:#000;-webkit-user-select:none;-ms-user-select:none;user-select:none;cursor:pointer}.Menu_Menu__1fk5z .Menu_item__22eVJ .Menu_active__1o221{color:#1890ff}

.Header_container__29Cyd{border-bottom:1px solid #BCBCBC;padding:0 2em}.Header_container__29Cyd .Header_header__1fCsm{width:90%;margin:0 auto;height:50px;display:flex;align-items:center;justify-content:space-between;padding-right:10px;padding-left:10px}


:root{--font-family: "Mulish", sans-serif}

